@import '~antd/es/style/themes/default.less';
@import '@{file-path}/assets/images/_imagepath';
@import '@{file-path}/_mixins';
@import '@{file-path}/_yjcommon';

@file-path: '../../../../../styles/';

.yjManageUserGroupTitle {
  background-color: @color-bg-titlewrapper;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .05);
  padding: 0 2em;

  h2 {
    color: @color-page-title;
    margin: .2em 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 99%;
  }
}

@primary-color: #419cb9;@link-color: #0e678e;@font-size-base: 14px;